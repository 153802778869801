import 'bootstrap/dist/css/bootstrap.min.css';
import VideoContent from './VideoContent';
import VideoInfoData from '../../../constants/VideoInfoData';
import { doc, updateDoc} from "firebase/firestore";
import { db, auth } from '../../../components/Firebase/firebase';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './VideoPreview.css';
import { BsFillArrowLeftCircleFill, BsFillCheckCircleFill } from 'react-icons/bs';
import fetchVideoData from '../../../components/FetchVideo/FetchVideo';

const VideoPreview = ({ userVideosCompleted }) => {
  const navigate = useNavigate();
  const currentURL = window.location.href;
  const numberString = currentURL.split("?")[1];
  const videoIndex = parseInt(numberString, 10);
  const videoData = VideoInfoData.YoutubeVideos[videoIndex];
  const youtubeLink = videoData.ytLink;
  const title = videoData.title;
  const videoDescription = videoData.description;
  const contentLinks = videoData.contentLinks;

  const [currentUser, setUser] = useState({});
  const [currentIndex, setCurrentIndex] = useState(videoIndex);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [videosCompleted, setVideosCompleted] = useState({});

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async currentUser => {
      setUser(currentUser);

      if (!userVideosCompleted) {
        const fetchedVideosCompleted = await fetchVideoData(currentUser.uid);
        const currentVideoInfo = fetchedVideosCompleted[videoIndex];
        setIsCompleted(currentVideoInfo.completed);
        setVideosCompleted(fetchedVideosCompleted);
        return;
      }

      setVideosCompleted(userVideosCompleted);
    });

    return () => unsubscribe();
  }, []);

  if (!currentUser) {
    window.location.href = '/login';
    return;
  }

  const handleLeftClicked = () => {
    const newIndex = currentIndex - 1;
    if (newIndex < 0) {
      return;
    }
    navigateToVideo(newIndex);
  }

  const handleRightClicked = () => {
    const newIndex = currentIndex + 1;
    if (newIndex >= VideoInfoData.YoutubeVideos.length) {
      return;
    }
    navigateToVideo(newIndex);
  }

  const navigateToVideo = async (index) => {
    setCurrentIndex(index)
    navigate(`?${index}`);
    const currentVideoInfo = videosCompleted[index];
    setIsCompleted(currentVideoInfo.completed);
  }

  const handleDoneClicked = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true)
    const newCompletedVideos = [...videosCompleted];
    newCompletedVideos[currentIndex].completed = !isCompleted;
    setVideosCompleted(newCompletedVideos);
    setIsCompleted(!isCompleted)
    
    const user = doc(db, "users", currentUser.uid);
    await updateDoc(user, {
      videoUserInfo: {
        chatGPT: newCompletedVideos
      }
    })

    setIsLoading(false)
  }

  const buttonContent = () => {
    return (isCompleted ? 
      <div> 
        Delkurs avklarad { "  " }
        <BsFillCheckCircleFill />
      </div> 
      : 'Markera som klar')
  }

  return (
    <div className='video-preview-container'>
      <a href={"/kurs"} alt={"tillbaka"} className="back"> <BsFillArrowLeftCircleFill /> Tillbaka till kursöverblick </a>
      <VideoContent
        title={title}
        ytLink={youtubeLink}
        description={videoDescription}
        contentLinks={contentLinks}
      />

      <div className='video-preview-buttons'>
        <button className='btn-green-small left-button' onClick={() => { handleLeftClicked(); }}>
          Föregående video
        </button>
        <button
          className={`btn-green-small ${isCompleted ? 'completed' : 'not-completed'}`}
          onClick={() => { handleDoneClicked() }}
        >

          {buttonContent()}
        </button>
        <button className='btn-green-small right-button' onClick={() => { handleRightClicked() }}>
          Nästa video
        </button>
      </div>
    </div>

  );
};

export default VideoPreview;
