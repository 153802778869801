import React from 'react';
import './Headings.css';

const Headings = ({ title, text }) => {
    return (
        <div className="headings">
            <h2>{title}</h2>
            <p>{text}</p>
        </div>
    )
}

export default Headings