import thingshappen  from '../assets/thingshappen.png';
import logofooter  from '../assets/logofooter.png';
import services01  from '../assets/services01.png';
import services02  from '../assets/services02.png';
import services03  from '../assets/services03.png';
import services04  from '../assets/services04.png';
import services05  from '../assets/services05.png';
import services06  from '../assets/services06.png';
import team01 from '../assets/team01.png';
import i1 from '../assets/i1.svg';
import i2 from '../assets/i2.svg';
import i3 from '../assets/i3.svg';
import i4 from '../assets/i4.svg';
import i5 from '../assets/i5.svg';
import i6 from '../assets/i6.svg';
import hero from '../assets/hero.png';
import chatGPTmascot from '../assets/new_openai_robot.png';
import carpenter from '../assets/carpenter.jpg';
import robotReading from '../assets/robot_reading.png';
import robotByDesk from '../assets/robot_by_desk.png';
import testimonial1 from '../assets/stephen_portrait.png';
import testimonial2 from '../assets/linn_portrait.png';
import hi from '../assets/hi.png';
import logo from '../assets/logo512.png';
import aiconsult from '../assets/ai_konsult_stockolm.jpg';
import aiface from '../assets/ai_face.jpg';
import brandlab from '../assets/brand_lab.png';
import artaiLogo from '../assets/artai_logo.png';
import sketchBench from '../assets/sketch_bench.png';
import johannesProfile from '../assets/johannes_profile.png';
import aiForBusiness from '../assets/ai_for_foretag.jpg';

export default {
    thingshappen,
    logofooter,
    services01,
    services02,
    services03,
    services04,
    services05,
    services06,
    team01,
    testimonial1,
    testimonial2,   
    carpenter,
    hero,
    chatGPTmascot,
    robotReading,
    i1,
    i2,
    i3,
    i4,
    i5,
    i6,
    hi,
    robotByDesk,
    logo,
    aiconsult,
    aiface,
    brandlab,
    artaiLogo,
    sketchBench,
    johannesProfile,
    aiForBusiness
};