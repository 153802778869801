import { auth } from '../../components/Firebase/firebase'
import { useEffect } from 'react';

const Logout = () => {

    const handleLogout = () => {
        auth.signOut();
        window.location.href = '/chatgpt-kurs';
    };

    useEffect(() => {
        handleLogout();
    }, []);

    return null;
};

export default Logout;