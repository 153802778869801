import 'bootstrap/dist/css/bootstrap.min.css';
import { Testimonials, Content, FaqList,  Benefits, Footer } from '..';
import { Usp } from '../../container/';
import HeroChatGPT from './HeroCGPT/HeroChatGPT';
import { auth } from '../../components/Firebase/firebase';
import React, { useState, useEffect } from 'react';
import TopLink from './TopLink'
import { Helmet } from 'react-helmet';
import LogEvent from '../../components/Firebase/LogEvent';

const ChatGPTCourse = () => {
  const [user, setUser] = useState({});

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "ChatGPT Utbildning",
    "description": "Omfattande kurs i ChatGPT och AI för att förbättra din produktivitet och kreativitet.",
    "provider": {
      "@type": "Organization",
      "name": "techlära",
      "url": "https://www.techlara.se"
    },
    "courseCode": "CGPT-101",
    "educationalLevel": "Nybörjare till avancerad nivå",
    "inLanguage": "sv-SE",
    "keywords": [
      "ChatGPT",
      "ChatGPT-kurs",
      "LLM kurs",
      "AI utbildning",
      "Artificiell intelligens",
      "Naturlig språkbehandling",
      "Produktivitetsverktyg",
      "Digital transformation",
      "Framtidskompetens",
      "Onlinekurs",
      "Svensk AI-utbildning",
      "AI kurs",
      "Prompt-engineering",
    ],
    "offers": {
      "@type": "Offer",
      "price": "799",
      "priceCurrency": "SEK"
    }
  };

  LogEvent('chatgpt_funnel_loaded')
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(currentUser => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleButtonClick = () => {
    window.location.href = '/kassa';
  };

  const btn = () => {
    return(
      <div>
        <button className="btn-green" onClick={ handleButtonClick }>Köp kurs</button>
        <br/><br/>
      </div>
    )
  }

  return (
    <div className="container">
      <Helmet>
      <title>ChatGPT utbildning | AI kurs ChatGPT | techlära.se </title>
        <link rel="canonical" href="https://techlara.se/chatgpt-kurs" />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <meta name="description" content="Omfattande kurs i ChatGPT och AI för att förbättra din produktivitet och kreativitet." />
      </Helmet>
      {user &&
        <TopLink/>
      }
      <HeroChatGPT />
      <Testimonials />
      <Usp />
      <Benefits />
      <FaqList />
      {btn()}      
      <Content />
      <Footer />
    </div>
  );
};

export default ChatGPTCourse;
