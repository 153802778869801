import { data } from '../../constants';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Menu.css';
import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../Firebase/firebase';
import images from '../../constants/images';
const Menu = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        });
        return () => unsubscribe();
    }, []);

    const handleLogout = () => {
        auth.signOut();
        window.location.href = '/chatgpt-kurs';
    };

    return (
        <Navbar collapseOnSelect expand="lg" bg="ligth" variant="ligth">
            <Container>
                <Navbar.Brand href="/"> 
                <img
        src={images.logo}
        width="70" // Adjust width as needed
        height="70" // Adjust height as needed
        className="d-inline-block align-top" // Keeps the logo aligned properly
        alt="Techlära Logo" // Alt text for the logo
    />
                </Navbar.Brand>
                
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav>
                        {data.Menu.map((item, index) => (
                            isLoggedIn && (data.Menu.length - 1 === index) ? (
                                <button className="btn-logout" onClick={handleLogout}>Logga ut</button>
                            ) : (
                                <Nav.Link href={
                                   item.link 
                                }>{item.text}</Nav.Link>
                            )
                        ))}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Menu