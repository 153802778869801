import { getAnalytics, logEvent } from 'firebase/analytics';
import { fireBaseApp } from './firebase';

const LogEvent = ({ eventName }) => {

  const consent = localStorage.getItem('cookieConsent');
  if (consent === 'accepted') {
    const analytics = getAnalytics(fireBaseApp);
    logEvent(analytics, eventName);
  }
};

export default LogEvent;