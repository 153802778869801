import React from 'react';
import { Headings } from '../../../components';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import './Services.css';

const Services = ( { title, text, cta, data } ) => {

  const renderUSPs = (usps, itemclass) => {
    return usps.split(', ').map((usp, index) => (
      itemclass === "bdark" ? 
      <li key={index} style={{ color: 'white' }}>{usp}</li> 
      : <li key={index}>{usp}</li>
    ));
  };

  return (
    <div id="services" className="d-block pt-md-4">
      <Headings title={title} text={text} />
      <div className="row">
        {data.map(({ titleone, titletwo, link, itemclass, usps, imgURL }, index) => (
          <div className="col-lg-6 col-12" key={index}>
            <div className={`row ${itemclass}`}>
              <div className="col-md-6 box">
                <div>
                  <span>{titleone} </span>
                  <span>{titletwo}</span>
                  <br/>
                  {renderUSPs(usps, itemclass)}
                </div>
                <a href={link} alt={titleone} className="readmore"> <BsFillArrowRightCircleFill /> {cta} </a>
              </div>
              <div className="col-md-6 text-end">
                {imgURL && (
                  <img src={imgURL} alt={titleone} className="img-fluid img-services" />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Services