import React from 'react';
import ProductHero from './ProductHero';
import Services from '../Start/Services/Services';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';
import { Helmet } from 'react-helmet';
import { data } from '../../constants';

const Products = () => {

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "AI-produkter",
    "description": "Vi hjälper dig att skapa AI-produkter. Utforska våra tjänster inom artificiell intelligens och maskininlärning.",
    "keywords": "AI-produkter, artificiell intelligens, maskininlärning, techlara, AI-tjänster",
    "url": "https://www.techlara.se/produkter"
  };


  return (
    <div className="start">
      <Helmet>
        <title>Vi hjälper dig att skapa en AI-produkt </title>
        <meta name="description" content="Vi hjälper dig att skapa AI-produkter. Vare sig det gäller att bygga en chatbot, en AI-app eller bygga automatiseringar." />
        <link rel="canonical" href="https://www.techlara.se/produkter" />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <ProductHero />
      <Services 
        title="Våra produkter" 
        text="Vi har skapat produkter i 10 år. Här är ett urval av våra produkter."
        cta="Visa produkt"
        data={data.ProductsData}
      />
      <div id="kontakt">
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default Products;
