import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Headings } from '../../components';
import './NewsFeed.css';
import { Footer } from '../../container';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import NewsData from '../../constants/NewsData';

const NewsFeed = () => {
  const location = useLocation();
  const [currentNews, setCurrentNews] = useState(location.state?.news);
  const [currentURL, setCurrentURL] = useState('');

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "headline": currentNews?.header,
    "datePublished": currentNews?.date,
    "image": currentNews?.imgURL,
    "articleBody": currentNews?.html,
    "author": {
      "@type": "Organization",
      "name": "techlära"
    },
    "publisher": {
      "@type": "Organization",
      "name": "techlära",
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `https://www.techlara.se/ainyheter/${currentURL}`
    },
    "keywords": [
      "AI-nyheter",
      "Artificiell intelligens",
      "Teknologiuppdateringar",
      "Digitala trender",
      "Framtidsteknologi",
      "Tekniknyheter",
      "AI-utveckling",
      "Innovationer inom AI",
      "Svensk AI-information",
      "Teknologiska framsteg"
    ]
  };

  const createUrlFromHeader = (header) => {
    return header.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
  };
  
  useEffect(() => {
    if (!currentNews) {
      NewsData.forEach(news => {
        const url = createUrlFromHeader(news.header);
        if (location.pathname.split('/').pop() === url) {
          setCurrentNews(news);
          setCurrentURL(url);
        }
      });
    } else {
      const url = createUrlFromHeader(currentNews.header);
      setCurrentURL(url);
    }
  }, [location.pathname, currentNews]);

  if (!currentNews) {
    return <div>Laddar...</div>;
  }

  const handleButtonClick = (link) => {
    console.log("Button clicked", link);
    window.location.href = link;
  };
  
  const button = (currentNews) => {
    return (
      <div className="news-cta">
        <button className="btn-green" onClick={() => handleButtonClick(currentNews.link)}>{currentNews.cta}</button>
      </div>
    )
  }

  const news = () => {
    return (
      <div className="news">
        <Headings title={currentNews.header} text={currentNews.date} />
        <div className="news-container">

          <img src={currentNews.imgURL} alt={currentNews.imgAlt} className="news-image" />
          <div className="news-text">
            {parse(currentNews.html)}
          </div>

          {currentNews.cta && button(currentNews)}

        </div>
      </div>
    ) 
  }

  return (
    <div>
      <Helmet>
      <title> {currentNews.header} </title>
        <link rel="canonical" href={`https://www.techlara.se/${currentURL}`} />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <meta name="description" content={currentNews.header} />
      </Helmet>
      {news()}
      <Footer />
    </div>
  );
};

export default NewsFeed