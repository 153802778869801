
const Loading = () => {
  return (
    <div>
      <h3 className='loading'>Laddar sidan...</h3>
    </div>
  );
}

export default Loading;

