import React from 'react';
import { Helmet } from 'react-helmet';
import { Headings } from '../../components';
import ConsultHero from './ConsultHero';
import { Footer } from '../../container';
import { data } from '../../constants/';
import Contact from '../Contact/Contact';
import { Team } from '../../container';

const Consult = () => {

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "AI-konsulttjänster",
    "description": "Professionella AI-konsulttjänster för företag. Vi erbjuder expertis inom AI-implementering, strategiutveckling och skräddarsydda AI-lösningar.",
    "provider": {
      "@type": "Organization",
      "name": "techlära",
      "url": "https://www.techlara.se"
    },
    "serviceType": "AI-konsultering",
    "areaServed": "Sverige",
    "offers": {
      "@type": "Offer",
      "price": "Kontakta oss för prisuppgift",
      "priceCurrency": "SEK"
    },
    "keywords": [
      "AI-konsult",
      "AI-expert",
      "AI-rådgivning",
      "AI-implementering",
      "AI-strategi",
      "Maskininlärning",
      "Dataanalys",
      "Företagsautomatisering",
      "Digitalisering",
      "AI-lösningar",
      "Affärsutveckling med AI",
      "AI-utbildning",
      "Prediktiv analys",
      "Chatbots",
      "Naturlig språkbehandling",
      "Computer Vision",
      "AI-etik",
      "AI för företag",
      "Miljövänlig AI",
      "Skräddarsydda AI-tjänster",
      "AI-konsultering Sverige"
    ]
  };

  const addLineBreaks = (description) => {
    return description.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== description.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const services = () => {
    return (
      <>
        {data.ServicesExtendedData.map(({ header, headerTagline, text }, index) => (
          <div className="consult-container" key={index}>
            <Headings title={header} text={headerTagline} />
            {addLineBreaks(text)}
          </div>
        ))}
      </>
    );
  }

  const handleButtonClick = () => {
    const contactSection = document.getElementById('kontakt');
    contactSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (

    <div className="consult-container">
      <Helmet>
        <title>AI-konsult | AI-expert | techlära.se </title>
        <meta name="description" content="Professionella AI-konsulttjänster för företag. Vi erbjuder expertis inom AI-implementering, strategiutveckling och skräddarsydda AI-lösningar." />
        <link rel="canonical" href="https://www.techlara.se/aikonsult" />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        
      </Helmet>
      <ConsultHero />

      <div id="kontakt">
        <Contact />
        <Team title="Konsulter" />
      </div>
      {services()}
      <br />
      <button className="btn-green" onClick={handleButtonClick}> Boka ett möte </button>
      <br /><br />
      <Footer />
    </div>
  );
};

export default Consult;