
import SignInView from './SignIn';
import React from 'react';
import './Login.css';
import { Helmet } from 'react-helmet';

const Login = () => {
  const handleBuyClick = () => {
    window.location.href = '/kassa';
  };

  return (
    <div className="login">
      <Helmet>
        <title>Login | techlära.se </title>
        <link rel="canonical" href="https://www.techlara.se/login" />
      </Helmet>
      <React.Fragment>
        <SignInView />

        <React.Fragment>
          <br /><br />
          Inte köpt kursen än?
          <button className="btn-green" onClick={handleBuyClick}>Köp kursen</button>
          <br /><br />
          Frågor? <a href="mailto:hej@techlara.se">hej@techlara.se</a>
        </React.Fragment>
      </React.Fragment>
    </div>
  );
};

export default Login;