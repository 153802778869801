import 'bootstrap/dist/css/bootstrap.min.css';
import { Footer } from '../..';
import VideoInfoData from '../../../constants/VideoInfoData';
import './Course.css';
import { images } from '../../../constants'
import { auth } from '../../../components/Firebase/firebase';
import React, { useState, useEffect } from 'react';
import Loading from '../../Loading/Loading';
import fetchVideoData from '../../../components/FetchVideo/FetchVideo';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import Headings from '../../../components/Headings/Headings';

const Course = () => {
  const [currentUser, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [videosCompleted, setVideosCompleted] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async currentUser => {
      setUser(currentUser);
      const fetchedVideosCompleted = await fetchVideoData(currentUser.uid);

      setVideosCompleted(fetchedVideosCompleted);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (!currentUser) {
    window.location.href = '/login';
    return;
  }

  const handleButtonClick = () => {
    window.location.reload();
  }

  const notVerified = () => {
    return (
      <div className='notVerifiedContainer'>
        <br />
        <h3>Det verkar inte som du har verifierat din e-mail än.</h3>
        <p>Du kan verifiera din e-post genom att klicka på länken som skickades till din inkorg.</p>
        <div>
          <button className="btn-green" onClick={handleButtonClick}>Jag har verifiert min mail</button>
        </div>
      </div>
    );
  }

  const verified = () => {

    const contentLinks = [
      {
        link: "https://docs.google.com/document/d/17L3u2g7AiDvxQdfCeSn6yszfxSmzIWxLx_RjLw7Ufzs/edit#heading=h.861a40a1az5d",
        title: "Alternativa verktyg - Testa andra AI-verktyg än ChatGPT "
      },
      {
        link: "https://docs.google.com/document/d/15zTDkfklCKfL3IKXSvbTZMECdRfE-iX2GrXenTIOhoI/edit?usp=sharing",
        title: "Exempelprompts - Välj bland ett 80 tal promptar i olika kategorier"
      },
      {
        link: "https://docs.google.com/document/d/12-YpieRKxaYb40LvOAynGrskJmGANL8gIP7IprG4sxM/edit?usp=sharing",
        title: "Custom GPTs - Här listar vi våra favorit CustomGPTs"
      }
    ]

    const isCompletedObject = (videoId) => {
      if (!videosCompleted) {
        return false;
      }
      return videosCompleted.find(vc => vc.id === videoId);
    }

    const handleItemPressed = (index) => {
      window.location.href = `/videopreview?${index}`;
    }

    const videoListItem = (index, video) => {
      return (
        <div key={index} className="video-item" onClick={() => handleItemPressed(index)}>
          {/* <img src={video.imgURL} alt="news" className="news-feed-image" /> */}
          
            <div className='video-description'>
              <h5 className='video-title'>{video.title}</h5>
              <p className='video-description'>{video.description.substring(0, 120)}...</p>
            </div>
            {isCompletedObject(video.id).completed &&
              <BsFillCheckCircleFill className='check-mark' color='var(--color-green)' size={50} />
            }
        </div>
      )
    }

    return (
        <div>
        <div className="row align-items-center">
          <h1 className="title"></h1>
          <Headings title="ChatGPT-kurs" />
          <div>
            Hej och välkomna till ChatGPT kursen som förhoppningsvis kommer förändra ditt sätt att arbeta för alltid.
            <br />
            Vi kommer gå igenom hur AI kan optimera dina arbetsflöden och spara dig massor med tid. Vi kommer visa ChatGPTs alla kraftulla verktyg. <br />
            Vi hoppas att den här kursen kommer hjälpa dig att bli effektivare i ditt arbete och effektivisera bort repetetativa och tråkiga uppgifter. <br />
            Är du ivrig och direkt vill hoppa in i några matnyttiga dokument har vi även listat dessa längst ner på sidan. <br />
          </div>

          <div className="col-md-8 col-12">
            <br /><br />
            {VideoInfoData.YoutubeVideos.map((video, index) => (
              <div key={index}>
                <div class="header">
                  {video.section.length > 0 && <br />}
                  <h3>{video.section}</h3>
                </div>
                {videoListItem(index, video)}
                <hr style={{ width: '60%' }} />
              </div>
            ))}
          </div>
          <div className="col-md-4 col-12">
            <img className="img-header" src={images.chatGPTmascot} alt="chatgpt-kurs-header" />
          </div>

          <div class="header">
            <br /><br /><br />
            Länkar och dokument
          </div>
          {contentLinks && contentLinks.map((link, index) => (
            <div key={index}>
              <br />
              {link.header && <h3>{link.header}</h3>}
              <a key={index} href={link.link} target="_blank">{link.title}</a>
              <br />
            </div>
          ))}
        </div>

        <br /><br /><br /><br />
        <Footer />
      </div>
    );
  }

  return (
    isLoading ? <Loading /> : currentUser.emailVerified ? verified() : notVerified()
  );
};

export default Course;