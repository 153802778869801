import React from 'react';
import { images } from '../../constants';
import {IconScroll} from '../../components';
import './Hero.css';

const Hero = () => {
  const handleButtonClick = () => {
    window.location.href = '/chatgpt-kurs'; // Redirect to '/chatgpt-kurs' on button click
  };
  
  return (
    <div className="hero">
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <h1 className="title"> Vi hjälper ditt företag att navigera i AI-djungeln </h1>
          <p> Trött på repetativa uppgifter? Techlära är här för att underlätta din vardag med den senaste AI-tekniken.</p>
          <p>I en tid där teknologiska framsteg sker snabbare än någonsin står många företag inför utmaningen att identifiera de rätta verktygen som inte bara uppfyller era aktuella behov utan också främjar långsiktig tillväxt och innovation. Det är här vi kan göra skillnad. </p>
          <p>Titta in vår nyss lanserade kurs i ChatGPT! </p>
          <button className="btn-green" onClick={ handleButtonClick }>Köp vår ChatGPT-kurs</button>
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4">
          <img className="img-fluid" src={images.hero} alt="design" />
        </div>
      </div>
      <IconScroll />
    </div>
    
  )
}

export default Hero