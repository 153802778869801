import 'bootstrap/dist/css/bootstrap.min.css';
import { Menu } from './components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ChatGPTCourse from './container/ChatGPTCourse/ChatGPTCourse'
import VideoPreview from './container/ChatGPTCourse/VideoPreview/VideoPreview';
import Course from './container/ChatGPTCourse/Course/Course';
import Login from './container/Login/Login'
import PaywallForm from './container/Paywall/PaywallForm';
import Logout from './container/Login/Logout';
import Products from './container/Products/Products';
import Start from './container/Start/Start';
import NewsFeed from './container/News/NewsFeed';
import News from './container/News/News';
import CookieConsent from "react-cookie-consent";
import ThankYou from './container/Paywall/ThankYou';
import Consult from './container/Consult/Consult';
import { useEffect } from 'react';

const App = () => {
  useEffect(() => {
    const disableHorizontalScroll = () => {
      document.body.style.overflowX = 'hidden';
    };

    disableHorizontalScroll();

    return () => {
      document.body.style.overflowX = 'hidden';
    };
  }, []);

  return (
    <Router>
      <div className="container">
        <Menu />
        <Routes>
          <Route path="/chatgpt-kurs" element={<ChatGPTCourse />} />
          <Route path="/videopreview" element={<VideoPreview />} />
          <Route path="/kurs" element={<Course />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/tack" element={<ThankYou />} />
          <Route path="/kassa" element={<PaywallForm />} />
          <Route path="/ainyheter" element={<NewsFeed />} />
          <Route path="/ainyheter/:slug" element={<News />} />
          <Route path="/aikonsult" element={<Consult />} />
          <Route path="/" element={<Start />} />
          <Route path="/produkter" element={<Products />} />
        </Routes>
        <CookieConsent
          location="bottom"
          buttonText="Acceptera"
          cookieName="consentCookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{ background: "#509D72", color: "#ffff", fontSize: "16px" }}
          onAccept={(acceptedByScrolling) => {
            if (!acceptedByScrolling) {
              localStorage.setItem('cookieConsent', 'accepted');
            }
          }}
          declineButtonText="Neka"
          declineButtonStyle={{ background: "#7B6F6F", color: "#ffffff", fontSize: "16px" }}
          enableDeclineButton
          onDecline={() => {
            localStorage.setItem('cookieConsent', 'declined');
          }}
        >
          Genom att klicka på "Acceptera" samtycker du till lagringen av cookies på din enhet.{" "}
        </CookieConsent>
      </div>
    </Router>
  );
};

export default App;
