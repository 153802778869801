import React, { useState } from 'react';
import { Headings } from '../../components';
import { data } from "../../constants";
import './Faq.css';

export const Faq = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  return (

    <div className="
    n">
      <button className="faq-question" onClick={toggleOpen}>
        {question}
        <span className={isOpen ? 'arrow up' : 'arrow down'} />
      </button>
      {isOpen && <div className="faq-answer">
        {answer}
        <br/>
        <br/>
      </div>}
    </div>
  );
};

export const FaqList = () => {

   return (
    <div className="faq-list">
      <Headings title="FAQ" text="Vanligt ställda frågor" />
      <br></br>
      {data.Faqs.map((faq, index) => (
        <Faq key={index} question={faq.question} answer={faq.answer} />
      ))}
      <br></br>
    </div>
  );
};