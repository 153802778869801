import React from 'react';
import { Contact, Footer, Hero, Services, Team, Community  } from '../../container';
import { Helmet } from 'react-helmet';
import { data } from '../../constants';

const Start = () => {

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "techlära - Utbildningar inom AI, WEB3 och framtidens teknik",
    "description": "Vi erbjuder kurser inom AI samt konsultering för ditt företag. Utforska våra tjänster inom artificiell intelligens, WEB3 och framtidsteknologi.",
    "url": "https://www.techlara.se/",
    "mainEntity": {
      "@type": "Organization",
      "name": "techlära",
      "url": "https://www.techlara.se/",
      "description": "Utbildningar och konsulttjänster inom AI, WEB3 och framtidens teknik",
      "sameAs": [
        "https://www.linkedin.com/company/techlara",
        "https://www.facebook.com/motionapps.ab",
        "https://www.instagram.com/techlara.se"
      ]
    },
    "offers": {
      "@type": "Offer",
      "itemOffered": [
        {
          "@type": "Service",
          "name": "AI-kurser",
          "description": "Utbildningar inom artificiell intelligens"
        },
        {
          "@type": "Service",
          "name": "AI-konsultering",
          "description": "Expertrådgivning inom AI för företag"
        }
      ]
    },
    "keywords": [
      "AI-utbildning",
      "WEB3-kurser",
      "Framtidsteknologi",
      "Artificiell intelligens",
      "Blockchain",
      "Maskininlärning",
      "Teknikkonsulting",
      "Digitala innovationer",
      "Teknikutbildning",
      "AI-implementering"
    ]
  };

  return (
    <div className="start">
      <Helmet>
        <title> Utbildningar och konsultering inom AI, WEB3 och framtidens teknik | techlära.se </title>
        <meta name="description" content="Utbildningar och konsultering inom AI, WEB3 och framtidens teknik" />
        <link rel="canonical" href="https://www.techlara.se/" />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <Hero />
      <Services 
        title="Tjänster" 
        text="Vi erbjuder kurser inom AI samt konsultering för ditt företag" 
        cta="Ta mig hit"
        data={data.ServicesData} 
      />
      <Community />
      <Contact /> 
      <Team title="Det här är vi" />
      <Footer />
    </div>
  );
};

export default Start;
