import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Headings } from '../../components';
import NewsData from '../../constants/NewsData';
import './NewsFeed.css';
import  { Footer } from '../../container';
import { Helmet } from 'react-helmet';

const NewsFeed = () => {
  const navigate = useNavigate();

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    "name": "AI-Nyheter",
    "description": "Läs om AI-nyheter, uppdateringar på siten och nytt på sociala medier",
    "url": "https://www.techlara.se/ainyheter",
    "mainEntity": {
      "@type": "ItemList",
      "itemListElement": NewsData.map((news, index) => ({
        "@type": "ListItem",
        "position": index + 1,
        "item": {
          "@type": "NewsArticle",
          "headline": news.header,
          "datePublished": news.date,
          "description": news.shortText,
          "image": news.imgURL,
        }
      }))
    },
    "keywords": [
      "AI-nyheter",
      "Artificiell intelligens",
      "Teknologiuppdateringar",
      "Digitala trender",
      "Framtidsteknologi",
      "Tekniknyheter",
      "AI-utveckling",
      "Innovationer inom AI",
      "Svensk AI-information",
      "Teknologiska framsteg"
    ]
  };

  const createUrlFromHeader = (header) => {
    return header.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
  };

  const handleNewsClick = (news) => {
    const urlSlug = createUrlFromHeader(news.header);
    navigate(`/ainyheter/${urlSlug}`, { state: { news } });
  };
  
  const NewsFeed = () => {
    return (
      <div className="news">
        <Headings className="headings" title="AI-Nyheter" text="Läs om AI-nyheter, uppdateringar på siten och nytt på sociala medier" />
        <div className="news-container">
          {NewsData.map((news, index) => (
            <div key={index} className="news-item" onClick={() => handleNewsClick(news)}>
              <img src={news.imgURL} alt="news" className="news-feed-image" />
              <div className="news-container">
                <h2 className="news-headings">{news.header}{" - "}{news.date}</h2>
                <p className="news-text">{news.shortText}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div>
      <Helmet>
      <title>AI-nyheter | techlära.se</title>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href="https://www.techlara.se/ainyheter" />        
        <meta name="description" content="Håll dig uppdaterad med de senaste AI-nyheterna och uppdateringar på vår plattform." />
      </Helmet>
      { NewsFeed() }
      <Footer />
    </div>
  );
};

export default NewsFeed;