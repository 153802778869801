import React, { useState } from 'react';
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db, auth } from "../../components/Firebase/firebase";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { images } from '../../constants';
import './PaywallForm.css';
import { Helmet } from 'react-helmet';
import VideoInfoData from '../../constants/VideoInfoData';

const PaywallForm = () => {

    const schemaMarkup = {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "ChatGPT Utbildning",
        "description": "Omfattande onlinekurs i ChatGPT och AI för att förbättra din produktivitet och kreativitet.",
        "offers": {
            "@type": "Offer",
            "price": "799",
            "priceCurrency": "SEK",
            "availability": "https://schema.org/InStock"
        },
        "provider": {
            "@type": "Organization",
            "name": "techlära",
            "url": "https://www.techlara.se"
        },
        "keywords": [
            "ChatGPT kurs",
            "AI utbildning",
            "Onlinekurs",
            "Produktivitetsverktyg",
            "Prompt engineering",
            "Digital kompetens",
            "Artificiell intelligens",
            "Företagsutbildning",
            "Svensk AI-kurs",
            "Framtidskompetens"
        ]
    };


    const organizationNumber = 'organizationNumber';
    const [formData, setFormData] = useState({
        email: '',
        companyName: '',
        organizationNumber: '',
        invoiceAddress: '',
        fullName: '',
        city: '',
        postalCode: '',
        billSent: false,
        subscribe: false,
        videoUserInfo: {
            chatGPT: VideoInfoData.YoutubeVideos.map((video) => ({
                id: video.id,
                completed: false
            }))
        },
        discountCode: 'none',
        agreeTerms: false,
    });

    const [pw, setPw] = useState("")
    const [errorString, setErrorString] = useState("")
    const [hasPaid, setHasPaid] = useState(false)

    const addUserToFirestore = async (user) => {
        try {
            await setDoc(doc(db, "users", user.uid), formData);
            setHasPaid(true)
            console.log("User added to Firestore!");
        } catch (error) {
            console.error("Error adding user to Firestore: ", error);
            setErrorString("Det blev något fel när vi skulle lägga till din användare i databasen: ", error);
        }
    };

    const handleChange = (e) => {
        setErrorString("")
        const { name, value, type, checked } = e.target;
        if (name === 'password') {
            setPw(value)
        } else {
            setFormData({
                ...formData,
                [name]: type === 'checkbox' ? checked : value,
            });
        }
    };

    const checkUserExistence = async () => {
        if (!auth.currentUser) {
            return false;
        }

        const uid = auth.currentUser.uid;
        const userSnapshot = await getDoc(doc(db, "users", uid));
        return userSnapshot.exists();
    };

    const createUser = async (e) => {
        e.preventDefault();
        createUserWithEmailAndPassword(auth, formData["email"], pw)
            .then((userCredential) => {

                const user = userCredential.user;
                sendEmailVerification(user)
                    .then(() => {
                        addUserToFirestore(user);
                        console.log('Verification email sent.');
                    })
                    .catch((error) => {
                        console.error(error);
                        setErrorString(error.message)
                    });
            })
            .catch((error) => {
                console.error("Error creating user: ", error);
                setErrorString(error.message)
            });
    };

    const handleLoginClick = () => {
        window.location.href = '/kurs';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (await checkUserExistence()) {
            setErrorString("Denna email är redan registrerad! Testa att logga in.");
            return;
        }

        if (handleError()) {
            return;
        }
        createUser(e);
    };

    const handleError = () => { 
        const errorMessage = isAllFieldsValid()
        if (errorMessage != "") {
            if(errorMessage.includes("auth/email-already-in-use")) {
                setErrorString("Denna email är redan registrerad! Testa att logga istälet.");
            } else {
                setErrorString(errorMessage);
            }
            return true;
        }
        return false;
    }

    const isAllFieldsValid = () => {
        const allFieldsFilled = Object.values(formData).filter(value => typeof value === 'string').every(value => value.trim() !== '');
        if (!allFieldsFilled) {
            return "Alla fält behöver vara ifyllda.";
        }

        const emailError = isNotCompanyEmail();
        if (emailError != "") {
            return emailError;
        }

        if (pw.length < 6) {
            return "Lösenordet måste vara minst 6 tecken.";
        }

        if (!validateOrgNumber()) {
            return "Organisationsnumret måste vara i korrekt format.";
        }

        return "";
    };

    const isNotCompanyEmail = () => {
        const email = formData["email"];
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email pattern
        const bannedDomains = [
            'gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'
        ];

        // Check if the email is in a valid format
        if (!emailRegex.test(email)) {
            return "Ange en riktig emailadress.";
        }

        // Extract the domain from the email
        const domain = email.split('@')[1];

        // Check if the domain is in the list of banned domains
        if (bannedDomains.includes(domain)) {
            return "Du måste ha en företagsmailadress. Har du inte en mailadress från ett företag, kontakta oss på hej@techlara.se";
        }

        return "";
    }

    const validateOrgNumber = () => {
        const formOrgNumber = formData[organizationNumber]
        const patternWithDash = /^\d{6}-\d{4}$/; // Pattern for '6 numbers-4 numbers'
        const patternWithoutDash = /^\d{10}$/;  // Pattern for '10 numbers'

        if (patternWithDash.test(formOrgNumber)) {
            return true;
        }

        // Check if the input matches the '10 numbers' format and needs formatting
        if (patternWithoutDash.test(formOrgNumber)) {
            // Insert a dash after the 6th number
            const formOrgNumberWithDash = formOrgNumber.slice(0, 6) + '-' + formOrgNumber.slice(6);
            formData[organizationNumber] = formOrgNumberWithDash;
            return true;
        }

        return false;
    }

    const textAndImage = () => {
        return (
            <div>
                 <Helmet>
                    <title>ChatGPT-kurs kassa | techlära.se </title>
                    <script type="application/ld+json">
                        {JSON.stringify(schemaMarkup)}
                    </script>
                </Helmet>
                <div className='topText'>
                    Redan köpt kursen? {" "}
                    <button className="btn-green-small" onClick={handleLoginClick}>Logga in</button>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-8 col-12"> 
                        <h1>ChatGPT-kurs för arbete & företagare.</h1>
                        <h3>Köp kursen som för alltid kommer att ändra ditt sätta att arbeta.</h3>
                        Var snäll och fyll i ditt företags information nedan. Vi mailar dig en faktura när registreringen är klar.
                        <br /><br />
                        <h3 className="text-gray-500 text-xl mb-4">Pris: 799kr ex. moms</h3>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-4"> 
                        <img className='image' src={images.robotReading} alt="Robot Reading" />
                    </div>
                </div>
            </div>
        );
    }

    const errorMessage = () => {
        return (
            <div className="error">
                <p>{"Något gick fel! " + errorString}</p>
            </div>
        );
    }

    const formView = () => {
        const localStrings = ['Emailadress*', 'Lösenord*', 'Företagsnamn*', 'Organisationsnummer*', 'Fakturaadress*', 'För & Efternamn*', 'Ort*', 'Postnummer*'];
        const fieldIds = ['email', 'password', 'companyName', organizationNumber, 'invoiceAddress', 'fullName', 'city', 'postalCode'];
        return (
            <React.Fragment>
                <div className="container mx-auto p-4">
                    { errorString && errorMessage() }
                    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">

                        <div className="mb-4">
                            {fieldIds.map((field, index) => (
                                <div key={index} className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={field}>
                                        {localStrings[index]}
                                    </label><br />
                                    <input
                                        style={{ width: '300px' }}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id={field}
                                        type={field === 'password' ? 'password' : 'text'}
                                        placeholder={localStrings[index]}
                                        name={field}
                                        value={formData[field]}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            ))}
                        </div>
                        { errorString && errorMessage() }
                        <br />

                        <div className="mb-4">
                            <span className='checkboxText' onClick={() => setFormData({ ...formData, subscribe: !formData.subscribe })}>
                                Få information om de senaste nyheterna och AI-verktygen {" "}

                                <input
                                    type="checkbox"
                                    id="subscribe"
                                    name="subscribe"
                                    checked={formData.subscribe}
                                    onChange={handleChange}
                                />
                            </span>
                        </div>

                        <div className="flex items-center justify-between">
                            <button className="btn-green" onClick={handleSubmit}> Köp kurs</button>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }

    const allFormContent = () => {
        return (
            <div>
                {textAndImage()}
                {formView()}
            </div>
        );
    }

    const pushToThankYou = () => {
        window.location.href = '/tack';
    }

    return (
        hasPaid ? pushToThankYou() : allFormContent()
    );
};

export default PaywallForm;
