import React from 'react';
import { images } from '../../constants';
import { IconScroll } from '../../components';
import './ConsultHero.css';

const ConsultHero = () => {
  const handleButtonClick = () => {
    const contactSection = document.getElementById('kontakt');
    contactSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="hero">
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <h1 className="title"> AI för företag</h1>
          <p> Trött på repetativa uppgifter? Techlära är här för att underlätta din vardag med den senaste AI-tekniken.</p>
          <p>Vi hjälper dig utnyttja kraften i artificiell intelligens för att effektivisera processer, öka produktiviteten och förbättra beslutsfattandet. </p>
          
          <p>Vårt mål är inte bara att informera, utan att transformera er verksamhet med smarta AI-val. Låt oss tillsammans skapa en framtid där ditt företag blomstrar i den digitala eran. </p>
          <button className="btn-green" onClick={handleButtonClick}> Boka ett möte </button>
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4">
          <img className="img-fluid" src={images.johannesProfile} alt="design" />
        </div>
      </div>
      <IconScroll />
    </div>
  )
}

export default ConsultHero;