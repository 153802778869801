import React from 'react';

const TopLink = () => {
  const handleToCourseClick = () => {
    window.location.href = '/kurs'
  };

  return (
    <div style={{ textAlign: 'center' }}>  
      Du är redan inloggad. {"  "}
      <button className='btn-green-small' onClick={handleToCourseClick}>Ta mig till kursen</button>
      <br /><br /><br />
    </div>
  )
}

export default TopLink;