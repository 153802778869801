
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../components/Firebase/firebase';

const fetchVideoData = async (uid) => {
  try {
    const docSnap = await getDoc(doc(db, "users", uid));
    if (!docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      return;
    }

    const data = docSnap.data();
    const videoUserInfo = data.videoUserInfo;
    return videoUserInfo.chatGPT;

  } catch (error) {
    //alert("Error fetching video data:", error)
    return null;
  }
};

export default fetchVideoData;

