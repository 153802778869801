import React from "react";
import "./Benefits.css";

const Benefits = () => {
  return (
    <div>
      <h2 >Efter kursen kommer du bland annat kunna: </h2>
      <div className="bullet-list">
        <h3><span className="checkmark">✔</span> Formulera kraftfulla prompts</h3>
        <h3><span className="checkmark">✔</span> Använda plugins och custom
          GPTs </h3>
        <h3><span className="checkmark">✔</span> Skapa AI bilder med DALLE</h3>
        <h3><span className="checkmark">✔</span> Låta AI analysera bilder</h3>
        <h3><span className="checkmark">✔</span> Skapa texter för sociala medier,
          nyhetsbrev, email m.m.  </h3>
        <h3><span className="checkmark">✔</span> Utföra dataanalys på försäljning,
          kundrecensioner och annan statistik</h3>
        <h3><span className="checkmark">✔</span> Låta ChatGPT korrekturläsa texter</h3>
        <h3><span className="checkmark">✔</span> Skapa och sammanfatta text </h3>
      </div>
    </div>
  );
};

export default Benefits;