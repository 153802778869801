

const YoutubeVideos = [

  // Introduktion till ChatGPT
  {
      id: "0",
      title: 'Intro',
      section: 'Introduktion till ChatGPT',
      ytLink: 'BtxeFMRaL8w?si=3zej9IaX5MvKTzdQ',
      description: 'Hej och välkomna till först introduktionskursen! \n\nKursen introducerar effektiv användning av ChatGPT i arbetslivet. Vi går även igenom hur du skapar en företagsvision och utbildning för nyanställda i detta avsnitt. \nI framtida avsnitt kommer länkar och filer vara länkade i den här sektionen. \n\nÄr du ivrig och direkt vill hoppa in i några matnyttiga dokument har vi även listat dessa här:\n',
      contentLinks: [
          {
              link: "https://docs.google.com/document/d/17L3u2g7AiDvxQdfCeSn6yszfxSmzIWxLx_RjLw7Ufzs/edit#heading=h.861a40a1az5d",
              title: "Alternativa verktyg - Testa andra AI-verktyg än ChatGPT "
          },
          {
              link: "https://docs.google.com/document/d/15zTDkfklCKfL3IKXSvbTZMECdRfE-iX2GrXenTIOhoI/edit?usp=sharing",
              title: "Exempelprompts - Välj bland ett 80 tal promptar i olika kategorier"
          },
          {
              link: "https://docs.google.com/document/d/12-YpieRKxaYb40LvOAynGrskJmGANL8gIP7IprG4sxM/edit?usp=sharing",
              title: "Custom GPTs - Här listar vi våra favorit CustomGPTs"
          }
      ]
  },
  {
      id: "1",
      title: 'Grunder',
      section: '',
      ytLink: 'eN_iPl8fWok?si=-3NbLAYwXzVoWaqw',
      description: 'Har du aldrig använt ChatGPT förut? I denna video går vi igenom grundfunktionaliteten i ChatGPT och hur du registrerar dig. Har du redan använt AIt ett tag förut kan det vara så att du redan kan allt i denna video - men det finns en risk att du missar nåt :)',
      contentLinks: [
          {
              header: "Mer info:",
              link: "https://docs.google.com/document/d/1CK1Q6pNKtkrmPIP2DkaFBzrUaTQY0sOqoWSwRUfVrRM/edit?usp=sharing",
              title: "Gratis vs PLUS"
          }
      ]
  },
  { 
      id: "2",
      title: 'Överblick',
      section: '',
      ytLink: 'SIfRnR22lv8?si=KHkpIJfqHk0EVAKs',
      description: 'I detta avsnitt får du en överblick över vad ChatGPT kan göra. Känner du att du har bra koll eller är ivrig att komma igång så kan du hoppa över detta avsnitt.',
  },

  // Grunderna i ChatGPT
  {
      id: "3",
      title: 'Google vs ChatGPT',
      section: 'Grunderna i ChatGPT',
      ytLink: 'rhDPNV158e4?si=SfnepCyqLGwyWiq1',
      description: 'I detta avsnitt jämför vi ChatGPT med Google.',
  },
  {
      id: "4",
      title: 'Vad är en LLM?',
      section: '',
      ytLink: 'YoyBeqsptLg?si=74C4KdwBmCpj4lZX',
      description: 'I detta avsnitt går vi igenom hur en LLM som ChatGPT fungerar.',
  },    
  {
      id: "5",
      title: 'Hallucinationer & fallgropar',
      section: '',
      ytLink: 'hFxn1ddhyXE?si=Dual-0z7oVUi1aXi',
      description: 'I det här avsnittet går vi igenom begränsningarna ChatGPT besitter - vi lär dig att undvika dess fallgropar och problem. \nVi berättar bland annat att ChatGPT4 har en avgränsad kunskapsbas som sträcker sig fram till april 2023 medan GPT3.5 har data fram till januari 2022.',
      contentLinks: [
          {
              header: 'AI detektorer:',   
              link: "https://gptzero.me/",
              title: "GPT zero"
          },
          {
              link: "https://smodin.io/sv/ai-innehallsdetektor",
              title: "Svensk innehållsdetektor"
          },
          {
              link: "https://corrector.app/sv/ai-detektor/",
              title: "Svensk AI-detektor"
          },
          {
              header: 'Tokenizer:',
              link: "https://platform.openai.com/tokenizer",
              title: 'Tokenizer - Se hur många token en prompt "kostar"'
          }
      ]
  },

  // Prompt engineering
  {
      id: "6",
      title: 'Prompt engineering',
      section: 'Prompt engineering',
      ytLink: 'vLYzysFXIoo?si=do3oUmsLZJMPey1D',
      description: 'I detta avsnitt utforskar vi konsten att skriva frågor effektivt genom prompt engineering, en viktig färdighet för att optimera användningen av ChatGPT och andra generativa AI-verktyg. Vi lär oss att anpassa frågeformuleringar för att uppnå bättre svar, genom tekniker som att definiera ChatGPT:s roll, vara specifik och detaljerad, specificera målgruppen, ge mer kontext, ange längd och användningsområde, samt att justera tonen. Genom att följa dessa steg kan du skräddarsy ChatGPT:s svar för att bättre matcha dina behov och önskemål. Avsnittet avslutas med praktiska exempel på hur dessa tekniker kan användas i verkliga scenarier, för att ge en djupare förståelse och verktyg för att effektivt kommunicera med AI.',
      contentLinks: [
          {
              header: "Länkar:",
              link: "https://docs.google.com/document/d/15F7JqeZt_QstV9v2nw-ZzAJTaEuljJ-bYRMAR17eZhs/edit?usp=sharing",
              title: "Prompt engineering - Teknik"
          },
          {
              link: "https://docs.google.com/document/d/15zTDkfklCKfL3IKXSvbTZMECdRfE-iX2GrXenTIOhoI/edit?usp=sharing",
              title: "Exempelprompts - Välj bland 100 tals promptar i olika kategorier"
          },
      ]
  },

  // ChatGPTs grundfunktioner
  {
      id: "7",
      title: 'Custom GPTs',
      section: 'ChatGPTs grundfunktioner',
      ytLink: '6th_zFaYL8s?si=ofgfO15FdSH2gpdg',
      description: 'I detta avsnitt går vi igenom hur du använder en av grundfunktionerna - CustomGPTs. Det är ett avsnitt du inte vill missa! Det här kraftfulla verktyget kan förändra ditt sätt att jobba för alltid. Vi visar dig runt på appstore plus att vi visar hur du gör din egen CustomGPT - och jag lovar, det är lättare än du tror!',
      contentLinks: [
          {
              link: "https://docs.google.com/document/d/12-YpieRKxaYb40LvOAynGrskJmGANL8gIP7IprG4sxM/edit?usp=sharing",
              title: "Custom GPTs - Här listar vi våra favorit CustomGPTs"
          }
      ]
  },
  {
      id: "8",
      title: 'OpenAIs API',
      section: '',
      ytLink: '4N2505mzbfI?si=F1oZiWMozmqL7VoN',
      description: 'I det här avsnittet utforskar vi hur integreringen av ChatGPT:s API kan ta din användning av AI till nästa nivå, genom att erbjuda en direkt länk till OpenAIs kraftfulla verktyg direkt i dina dagliga applikationer, såsom Google Sheets. Vi dyker ner i de mer avancerade möjligheterna som öppnas upp med API:et och hur det kan effektivisera och automatisera arbetsflöden på sätt som tidigare varit otänkbara. Ett praktiskt exempel demonstrerar hur man enkelt kan rensa och formattera stora mängder data i Google Sheets med hjälp av GPT for Work-pluginet, vilket sparar massor med tid.',
      contentLinks: [
          {
              header: "Länkar:",
              link: "https://platform.openai.com/",
              title: "OpenAIs API-plattform - Gå in på API-keys"
          },
          {
              header: "Länkar:",
              link: "https://gptforwork.com/",
              title: "GPT for work"
          }
      ]
  },
  {
      id: "9",
      title: 'Dall-E',
      section: '',
      ytLink: 'OfoOcyjpDn4?si=RdhhKddkiRMs7JX4',
      description: 'I detta avsnitt utforskar vi potentialen i OpenAI:s bildgenereringsverktyg DALL-E, en revolutionerande innovation som möjliggör skapandet av bilder från textbeskrivningar. DALL-E integreras smidigt med ChatGPT, vilket breddar användningsområdena betydligt – från marknadsföringsmaterial och produktidéer till berikade affärspresentationer. Vi guidar dig genom processen att skapa bilder, från den initiala beskrivningen till slutlig nedladdning, och delar tips för att uppnå de bästa resultaten. Vi belyser även alternativ som Midjourney och verktyg för att skala upp bildernas upplösning för ännu högre kvalitet. Denna delkurs är tänkt att ge dig verktygen för att effektivt utnyttja DALL-E i ditt företags visuella strategi.',
      contentLinks: [
          {
              header: "Länkar:",
              link: "https://prompthero.com/",
              title: "Prompt Hero - Bli inspirerad av andras promptar och bilder"
          },
          {
              link: "https://replicate.com/nightmareai/real-esrgan/examples",
              title: "Uppskalningsverktyg"
          }
      ]
  },
  {
      id: "10",
      title: 'ChatGPT Vision',
      section: '',
      ytLink: 'jFBli4UYTZk?si=wNkQF1PAopZjeuqG',
      description: 'Detta avsnitt belyser ChatGPT Vision, en ny funktion som utvidgar ChatGPT:s förmågor till att inte enbart hantera text utan även analysera och tolka bilder. Genom praktiska exempel visar vi hur denna funktion kan tillämpas i olika scenarier som passar företagare perfekt! Från att ge en detaljerad analys av företagsfinanser, identifiera objekt såsom kablar, till att tolka komplexa flödesscheman och hantera handskrivna anteckningar. Detta avsnitt är en ögonöppnare som kommer få dig att fundera hur du kan använda verktyget för att effektivisera ditt företags processer.',
  },
  {
      id: "11",
      title: 'ChatGPT Voice',
      section: '',
      ytLink: 'gRbOQZb3UbM?si=6CrRAb85dOS5aUIg',
      description: 'I detta avsnitt utforskar vi du kan konversera, med rösten! En innovation som förändrar hur vi interagerar med AI. Genom att tala direkt med ChatGPT kan användare nu engagera sig i naturliga samtal utan att behöva skriva. Detta har särskilt visat sig vara användbart för språkinlärning, där praktiska konversationsscenarion, såsom att beställa mat på en restaurang på ett främmande språk, erbjuder en verklighetstrogen övningsmiljö. \n \n Vi tar även upp hur ChatGPT Voice kan underlätta vardagliga uppgifter, som att föra anteckningar under en bilfärd, vilket visar på dess bredd av användningsområden.',
  },
  {
      id: "12",
      title: 'Dataanalys',
      section: '',
      ytLink: 'mx0JRRrXVxA?si=Nqycu91P4ACes9rz',
      description: 'I dagens avsnitt dyker vi in i en riktig effektiviserare för produktiviteten – möjligheten att ladda upp och analysera Excel- och CSV-filer. Denna funktion förvandlar ChatGPT till ett kraftfullt verktyg för dataanalys, som inte bara kan hantera siffror utan även tillhandahålla insikter och rekommendationer.\n\n Genom en praktisk demonstration med försäljningsdata för en e-handlare utforskar vi hur ChatGPT kan gå igenom en dataanalytikers tankeprocess, från att dra slutsatser till att ge strategiska råd baserat på analysen. \n\n Genom att använda funktionen för att ladda upp filer, kan du enkelt be ChatGPT att utföra en detaljerad dataanalys och presentera den på ett sätt som är lättförståeligt. Detta öppnar upp för en ny dimension av användbarhet för alla som hanterar data, oavsett om det är för affärsanalys, marknadsföringsstrategier, eller produktutveckling.',
      contentLinks: [
          {
              header: "Länkar:",
              link: "https://chat.openai.com/g/g-52FEjkr1P-statistic-data-analyst",
              title: "Statistic & Data Analyst"
          },
      ]
  },

  // Kommunikation
  {
      id: "13",
      title: 'Sociala medier',
      section: 'Kommunikation',
      ytLink: 'KNIYfLLcnlU?si=uYDX_qjuAvtBAPAm',
      description: 'I detta avsnitt utforskar vi hur ChatGPT kan bli din kreativa partner i sociala medier. Att hitta inspiration och skapa engagerande innehåll kan ofta vara en utmaning, men med ChatGPT:s hjälp kan du enkelt generera idéer, texter och anpassade hashtags som passar just din publik och den plattform du använder. Vi diskuterar hur ChatGPT kan användas för att inte bara skapa innehåll utan också för att hålla sig uppdaterad med de senaste trenderna, vilket gör ditt innehåll relevant och intressant.',
      contentLinks: [
          {
              header: "Custom GPTS:",
              link: "https://chat.openai.com/g/g-alKfVrz9K-canva",
              title: "Skapa visuellt content för sociala media"
          },
          {
              link: "https://chat.openai.com/g/g-GvcYCKPIH-youtube",
              title: "Sammanfattar YouTube videos"
          },
          {
              link: "https://chat.openai.com/c/77c09fb2-a304-4411-bce4-a9b1921987d4",
              title: "Visla - Skapa sociala medievideos"
          },
          {
              link: "https://chat.openai.com/g/g-h8l4uLHFQ-video-maker",
              title: "Video Maker"
          },
          {
              link: "https://chat.openai.com/g/g-H73sLSP2I-social-media",
              title: "Agererar som en Social media manager"
          }
      ]
  },
  {
      id: "14",
      title: 'Email',
      section: '',
      ytLink: '-S3-rnmQJrM?si=C4wcfV-o1dKRrCQG',
      description: 'I dagens avsnitt tar vi oss an utmaningen att skriva effektfulla email. Många av oss ser inte fram emot att skriva e-post, men med ChatGPT kan denna process bli betydligt enklare och mer anpassad. Vi går igenom hur ChatGPT kan användas för att skapa specialanpassade e-post för olika yrkesgrupper, från att omformulera befintliga utkast till att generera helt nya meddelanden. Genom att bryta upp uppdragen i steg, anpassa tonen och göra meddelandena mer lättlästa med punktlistor kan ChatGPT spara oss tid och förbättra vår kommunikation.',
  },
  {
      id: "15",
      title: 'Korrekturläsning',
      section: '',
      ytLink: 'BOFHJ8UpfdI?si=XiATqCpkVXDTmHEK',
      description: 'I detta avsnitt utforskar vi ChatGPT som ett verktyg för textgranskning och optimering. Med ChatGPT kan användare enkelt förbättra sina texter genom att kontrollera grammatik, stavning, tydlighet och stil i en enda genomgång. Genom att ge specifika instruktioner kan användare få hjälp med att göra texten mer sökmotorsoptimerad, engagerande eller säljande. Exemplen illustrerar hur ChatGPT kan omformulera meningar, korrigera fel, och öka textens koncishet.',
  },
  {
      id: "16",
      title: 'Översättning',
      section: '',
      ytLink: 'VionNpYCvh0?si=B8thW5N-YpD7a4Ef',
      description: 'I detta avsnitt går vi igenom ChatGPT:s roll som översättningsverktyg. Det belyser enkla steg för att översätta webbinnehåll, marknadsföringsmaterial och andra texter från ett språk till ett annat, utan behov av att anlita en professionell översättare. Med ChatGPT kan användare snabbt anpassa översättningar till specifika lokala marknader, vilket demonstreras genom exempel på översättning av e-handelstexter. Dessutom jämförs ChatGPT med traditionella översättningsverktyg, framhävande dess förmåga att inte bara översätta utan också optimera texten efter användarens behov. Tänk på att även fast ChatGPT i många fall är bättre än google translate så gör den ofta misstag. Ett vanligt misstag jag sett en del är att den översätter vissa engelska termer direkt, även fast motsvarande benämning inte riktigt finns i svenskan.',
  },
  {
      id: "17",
      title: 'Sammanfatta text med mera',
      section: '',
      ytLink: 'YOchXFrQNjY?si=yMQFxceU7f4HwNc5',
      description: 'I detta avsnitt utforskar vi ChatGPT:s förmåga att förbättra skrivprocesser. Vi tar en titt på hur ChatGPT kan effektivisera och förbättra skrivandet genom att sammanfatta artiklar, skapa engagerande utkast, och producera detaljerade produktbeskrivningar. Genom att använda ChatGPT för dessa uppgifter kan tidskrävande skrivprocesser omvandlas till snabba och smidiga.  Vi kommer även att inkludera en PDF med olika prompter för att ge praktiska exempel på hur dessa funktioner kan tillämpas i verkliga scenarier.',
      contentLinks: [
          {
              header: "Exempelprompts:",
              link: "https://docs.google.com/document/d/15zTDkfklCKfL3IKXSvbTZMECdRfE-iX2GrXenTIOhoI/edit?usp=sharing",
              title: "Exempelprompts - Välj bland 100 tals promptar i olika kategorier"
          },
      ]
  },

  // Andra användningsområden
  {
      id: "18",
      title: 'Research',
      section: 'Andra användningsområden',
      ytLink: 'PpZQYHDi2V8?si=f4YeaL4zGhi41QFJ',
      description: 'I den här delkursen utforskar vi ChatGPT:s roll som ett verktyg för snabb och effektiv efterforskning, som ett alternativ till traditionella sökmotorer. Genom att direkt ställa frågor till ChatGPT kan du spara tid och effektivisera din research inom alla tänkbara områden. Vi visar detta genom ett praktiskt exempel där ChatGPT används för att utforska en affärsidé, vilket illustrerar hur ChatGPT kan sammanställa och analysera information på ett sätt som traditionellt skulle kräva betydligt mer tid och arbete.',
  },
  {
      id: "19",
      title: 'Omdömen',
      section: '',
      ytLink: 'UfpL3hnJnig?si=lPviGNQTjD8UrPPB',
      description: 'I detta avsnitt lär du dig hur du effektivt kan hantera och analysera kundrecensioner med ChatGPT, oavsett om det handlar om recensioner från Ikea på Trustpilot eller feedback från andra betygsättningsplattformar som Google Reviews eller Appstore. Vi visar hur du kan spara tid och hantera känsliga situationer genom att skapa professionella och empatiska svar som tar hand om kundens oro samtidigt som ditt företags rykte upprätthålls. Detta avsnitt ger dig verktygen att effektivisera din kundservice!',
  },
  {
      id: "20",
      title: 'Sökordsoptimering',
      section: '',
      ytLink: 'YMzadibYFtY?si=3mWyF6Fa1zXq-9uL',
      description: 'I detta avsnitt tittar vi på SEO (Search Engine Optimisation) och hur du kan använda ChatGPT för att förbättra din hemsidas synlighet på Google. Vi visar hur ChatGPT kan assistera i On-page SEO-arbete genom sökordsanalys, skapande av kategoritexter och bloggartiklar, samt ge råd för att förbättra din sida. Genom praktiska exempel och tips får du lära dig att effektivisera din SEO-strategi med hjälp av AI.',
      contentLinks:
          [
              {
                  header: "Länkar:",
                  link: "https://gemini.google.com/app",
                  title: "Googles Gemini"
              }
          ]
  },
  {
      id: "21",
      title: 'Granska CV',
      section: '',
      ytLink: '0mVslDjYmHE?si=2ZFsKYD12LuQRQhu',
      description: 'I denna delkurs tittar vi på hur du med ChatGPT kan effektivisera rekryteringsprocessen genom snabb och enkel sammanfattning av CV:n. Genom att använda en enkel prompt kan ChatGPT ge dig en överblick över kandidatens kompetenser och erfarenheter baserat på ett flersidigt CV, komprimerat till en kort sammanfattning. Detta gör det lättare för dig att snabbt avgöra vilka kandidater som bör gå vidare i urvalsprocessen, vilket sparar både tid och energi.',
      contentLinks:
          [
              {
                  header: "Länkar:",
                  link: "https://chat.openai.com/g/g-FP6lgyUV1-cv-reviewer",
                  title: "CV Reviewer"
              }
          ]
  },
  {
      id: "22",
      title: 'Programmering',
      section: '',
      ytLink: 'P2Ky4Shgat4?si=e1UENNIkj01qRJuN',
      description: 'I detta avsnitt fokuserar vi på hur du kan använda ChatGPT för att lära dig programmering och skapa digitala sidor och produkter, även utan djupgående programmeringskunskaper. Vi visar hur ChatGPT kan agera som din personliga lärare, genom att anpassa svaren efter din kunskapsnivå och ge dig specifika kodexempel och förklaringar. Vi belyser också en framgångssaga där en kollega utan tidigare programmeringserfarenhet lyckades bygga en hemsida med hjälp av ChatGPT, vilket illustrerar verktygets förmåga att underlätta iterativ utveckling.',
      contentLinks:
          [
              {
                  header: "Custom GPTs:",
                  link: "https://chat.openai.com/g/g-n7Rs0IK86-grimoire",
                  title: "Skapa en webbplats med en mening. Med mera."
              },
              {
                  header: "Länkar:",
                  link: "https://chat.openai.com/g/g-0fiJrSSdG-screen-shot-to-code",
                  title: "Denna enkla app konverterar en skärmdump till kod (HTML/Tailwind CSS, eller React eller Vue eller Bootstrap)."
              }
          ]
      
  },
  {
      id: "23",
      title: 'Målgruppsanalys',
      section: '',
      ytLink: 'OQ4G-WijGXE?si=ygQL5rtBmRLZqUs7',
      description: 'Välkommen till en ny delkurs där vi går igenom hur du kan identifiera din målgrupp för ditt företag. Att hitta rätt målgrupp är avgörande för framgången med dina produkter, men det kan vara en utmaning att definiera vem din idealkund faktiskt är. I detta avsnitt lär vi oss att skapa en detaljerad persona, en fiktiv representation av din idealkund, genom att iterativt använda ChatGPT för att definiera och förfina kundens egenskaper. Genom att förstå din målgrupps behov och preferenser kan du bättre rikta dina marknadsföringsinsatser och produktutveckling.',
  },
];

export default { YoutubeVideos };

