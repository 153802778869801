import React from 'react';
import { IconScroll } from '../../../components';
import './HeroChatGPT.css';

const HeroChatGPT = () => {

  return (
    <div className="hero">
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <h1 className="title">Kurs i ChatGPT</h1>
          {/* <h2 className='header'>2.5h on-demand AI-kurs </h2> */}

          <p>
            Vår utbildning lär dig alla grunder
            i ChatGPT för att automatisera din arbetsdag
            och spara +1.5 h per dag. 
          </p>
          <div className="grid-container">
            {/* <div className="grid-item"><span className="dot">9.5<span className="star">★</span>/ 10 i betyg</span> </div> */}
            <div className="grid-item"><span className="dot">Svensk kursledare</span></div>
            <div className="grid-item"><span className="dot">2.5h on-demand</span></div>
          </div>
          <button className="btn-green" onClick={() => window.location.href='/kassa'}>Köp kurs</button> 
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4 video-container">
          <iframe
            className="rounded-corners"
            src="https://www.youtube.com/embed/isR5jUgE0fc?si=YKwnmXeyFbwCBlVj"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen>
          </iframe>
        </div>
      </div>
      <br/><br/>
      <IconScroll />
    </div>
  )
}

export default HeroChatGPT;
