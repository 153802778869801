import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../../components/Firebase/firebase";
import { Helmet } from 'react-helmet';

const SignInView = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorString, setErrorString] = useState("")

  const signIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        window.location.href = '/kurs';
      })
      .catch((error) => {
        if (error.message.includes("auth/invalid-credential")) {
          setErrorString("Det verkar som du har knappat in fel uppgifter. Var snäll och försök igen.")
        } else {
          setErrorString("Nåt gick snett: " + error)
        }
      });
  };

  return (
    <div className="sign-in-container">
      <Helmet>
        <title>Login ChatGPT kurs | techlära.se </title>
      </Helmet>
      <form onSubmit={signIn}>
        <h1>Logga in</h1>
        <input
          type="email"
          placeholder="Din epostaddress"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <br />
        <input
          type="password"
          placeholder="Lsenord"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></input>
        {errorString &&
          <div className="errorMessage">
            <p>{"Något gick fel! " + errorString}</p>
          </div>
        }
        <br />
        <button type="submit" className="btn-green login-btn">Logga in</button>
      </form>
    </div>
  );
};

export default SignInView;
