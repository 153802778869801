import React from 'react';
import { images } from '../../constants';
import './ProductHero.css';

const ProductHero = () => {
  const handleButtonClick = () => {
    const contactSection = document.getElementById('kontakt');
    contactSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="hero">
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <h1 className="title"> Våra produkter </h1>
          <p> Vi har byggt produkter i 10 år, här är resultatet.</p>
          <p>Våra produkter har använts av miljontals människor och vi har lång erfarenhet av att bygga digitala produkter. Tillsammans kan vi skapa framtidens produkter. </p>
          <button className="btn-green" onClick={handleButtonClick}> Behöver du hjälp att bygga en produkt? </button>
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4">
          <img className="img-fluid" src={images.robotByDesk} alt="design" />
        </div>
      </div>
    </div>
  )
}

export default ProductHero;