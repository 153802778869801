import images from './images';

const Menu = [
    {
        text: 'ChatGPT Kurs',
        link: '/chatgpt-kurs',
    },
    {
        text: 'AI-Konsult',
        link: '/aikonsult',
    },
    {
        text: 'AI-nyheter',
        link: '/ainyheter',
    },
    {
        text: 'Om Techlära',
        link: '/',
    },
    {
        text: 'Logga in',
        link: '/login',
    },
];

const Team = [
    {
        name: 'Johannes Olsson',
        position: 'Konsult & föreläsare',
        info: 'Johannes har sedan ungdomen varit en techentusiast som byggt hemsidor, appar och alltid anammat den senaste tekniken. I 10 år arbetade han som iOS utvecklare men har nu specialiserat sig inom AI',
        photo: images.team01,
        linkedin: '#',
    },
];

const USP = [
    {
        header: 'Konkreta exempel',
        info: 'Allt material kommer från egen erfarenhet och är specialanpassat för dig som företagare eller för att klara av dina arbetsuppgifter.',
        photo: images.i6,
    },
    {
        header: 'Inga förkunskaper',
        info: 'Kurs anpassad för att inte kräva några förkunskaper. Efter kursen kommer du kunna använda ChatGPT som en avancerad användare',
        photo: images.i1,
    },
    {
        header: '2.5h över 25 delkurser',
        info: 'Förutom ChatGPT kommer du bland annat använda relaterade verktyg så som dataanalys och bildskapande med DALLE.',
        photo: images.i2,
    },
    {
        header: 'Välj tid och plats själv',
        info: 'Du kommer åt den inspelade kursen via vår plattform och gör kursen i din egna takt på tid som passar dig. Du har möjlighet att se respektive del flera gånger. ',
        photo: images.i3,
    },
    {
        header: 'Automatiserad arbetsdag',
        info: '95% av våra deltagare har möjlighet att automatisera 1-3H av sin arbetsdag med ChatGPT och AI. Vi visar hur. ',
        photo: images.i4,
    },
    {
        header: 'Kurs på Svenska',
        info: 'Kurs och allt material är på Svenska för att passa den svenska arbetsmarknaden.  ',
        photo: images.i5,
    },
];

const ServicesData = [
    {
        titleone: 'Kurs i',
        titletwo: 'ChatGPT',
        link: '/chatgpt-kurs',
        usps: '2.5h när du vill, 25 delkurser, Välj tid och plats själv',
        itemclass: 'blight',
        imgURL: images.services01,
    },
    {
        titleone: 'Zapier &',
        titletwo: 'Make automatisering',
        link: '/aikonsult',
        usps: 'Ta bort repetativa uppgifter, Sparar timmar',
        itemclass: 'bgreen',
        imgURL: images.services02,
    },

    {
        titleone: 'OpenAI API',
        titletwo: 'Integration',
        link: '/aikonsult',
        usps: 'Bygg en chatbot, automatisera ditt företag',
        itemclass: 'bdark',
        imgURL: images.services04,
    },   

    {
        titleone: 'Anlita en',
        titletwo: 'AI-konsult',
        link: '/aikonsult',
        usps: 'Vi hjälper dig hitta punkter att effektivisera, Bygger och optimerar AI-lösningar',
        itemclass: 'blight',
        imgURL: images.johannesProfile,
    },
    {
        titleone: 'Våra',
        titletwo: 'produkter',
        link: '/produkter',
        usps: 'Vi har byggt produkter i 10 år, Här är resultatet',
        itemclass: 'bgreen',
        imgURL: images.brandlab,
    },
]

const ProductsData = [
    {
        titleone: 'Brand',
        titletwo: 'Lab',
        link: 'https://apps.apple.com/us/app/brand-lab-story-video-maker/id1375539591',
        usps: 'Skapa en story, video eller annat innehåll, för sociala medier',
        itemclass: 'blight',
        imgURL: images.brandlab,
    },
    {
        titleone: 'Sketch',
        titletwo: 'Bench',
        link: 'https://apps.apple.com/us/app/stickers-filter-sketch-bench/id937042017',
        usps: 'Skapa unika filter, stickers och annat innehåll, för sociala medier',
        itemclass: 'bdark',
        imgURL: images.sketchBench,
    },
    {
        titleone: 'Bike ',
        titletwo: 'hotel planner',
        link: 'https://bikehotelplanner.com/',
        usps: 'Planera din cykelresa',
        itemclass: 'bdark',
        imgURL: "",
    },
    {
        titleone: 'Art',
        titletwo: 'AI',
        link: 'https://artai.se/',
        usps: 'AI genererad konst som posters',
        itemclass: 'blight',
        imgURL: images.artaiLogo,
    },
]

const ServicesExtendedData = [
    {
        header: 'Automatisera din verksamhet',
        headerTagline: 'Vi automatiserar dina flöden',
        text: `I en värld där tid är pengar, erbjuder Techlära skräddarsydda automatiseringslösningar som förvandlar dina arbetsprocesser till så lite som ett knapptryck. Med hjälp av ledande verktyg som Zapier och Make, optimerar vi dina dagliga uppgifter och skapar automatiserade flöden som sparar både tid och pengar.

        Vi skapar och implementerar automatiserade lösningar:
        
        Vi analyserar dina nuvarande arbetsflöden och identifierar möjligheter för automatisering. Vårt team designar och implementerar anpassade flöden som integrerar dina applikationer och tjänster, så att data kan flyta fritt och effektivt utan manuellt ingripande.
        
        Låt oss hjälpa dig att navigera i automatiseringens värld och transformera ditt sätt att arbeta. Kontakta oss idag för en konsultation och börja din resa mot en mer automatiserad och effektiv arbetsdag.`,
    },
    {
        header: 'OpenAI API integration',
        headerTagline: 'Öppna upp möjligheterna med OpenAIs API',
        text: `Techlära är din partner för integrera OpenAI:s kraftfulla API:er, som förvandlar hur ditt företag interagerar med kunder och optimerar interna processer. Genom att tillämpa det senaste inom AI-teknologi, anpassar vi smarta, skalbara lösningar som tar din verksamhet till nästa nivå.

        Vi utformar och implementerar anpassade AI-lösningar med OpenAI:s teknologi, vilket möjliggör automatisering av kundtjänst, innehållsgenerering och mycket mer.
       
           Vår expertis inom AI och OpenAI:s API möjliggör innovation och skapar nya möjligheter för ditt företag att växa och utvecklas.
       
           Låt oss hjälpa dig att effektivisera din verksamhet och skapa nya kundupplevelser med OpenAI:s API. Ta steget idag och transformera din affärsmodell med framtidens teknologi.`,
    },

    {
        header: 'AI Expert Stockholm',
        headerTagline: 'Din AI konsult i storstaden',
        text: `Vill du träffas för ett fysiskt möte? Inga problem! Vi håller till i Stockholm och träffas gärna här eller om du inte har möjlighet att komma till Stockholm så kan du boka ett möte via vår Calendly. `,
    },
]


const CaseStudies = [
    {
        text: 'For a local restaurant, we implemented a targeted PPC campaign that resulted in a 50% increase in website traffic and a 25% increase in sales.',
        link: '#',
    },
    {
        text: 'For a B2B software company, we developed an SEO strategy that resulted in a first page ranking for key keywords and a 200% increase in organic traffic.',
        link: '#',
    },
    {
        text: 'For a national retail chain, we created a social media marketing campaign that increased followers by 25% and generated a 20% increase in online sales.',
        link: '#',
    },
];

const Testimonials = [
    {
        name: 'Stephen Svanholm - ',
        position: 'Arstist manager på Svanholm Artists Management',
        testimonial: '"Johannes har skapat en fantastisk kurs här. Han leder dig igenom hur vi kan utnyttja ChatGPT i vårt dagliga arbete, och ger oss en rik förståelse av dess kraft. Inom ett par timmar hade jag skapat en egen Custom GPT. Starkt rekommenderat!"',
        photo: images.testimonial1,
    },
    {
        name: 'Linn Kristensen - ',
        position: 'Marketing Specialist på Kristensen Consulting',
        testimonial: '"Jag har lärt mig mycket nytt, och fick en bra översikt över vad som är möjligt samt inspiration till hur och vad man kan använda ChatGPT till. Det känns som att kursen passar perfekt till personer som har provat på och använt lite, men inte ser eller förstår den fulla potentialen än (precis som jag) och nybörjare eller de som aldrig har testat förut."',
        photo: images.testimonial2,
    },
    // {
    //     name: 'John Smith',
    //     position: 'Marketing Director at XYZ Corp',
    //     testimonial: '"We have been working with Positivus for the past year and have seen a significant increase in website traffic and leads as a result of their efforts. The team is professional, responsive, and truly cares about the success of our business. We highly recommend Positivus to any company looking to grow their online presence."',
    //     photo: images.team01,

    // },
    // {
    //     name: 'John Smith',
    //     position: 'Marketing Director at XYZ Corp',
    //     testimonial: '"We have been working with Positivus for the past year and have seen a significant increase in website traffic and leads as a result of their efforts. The team is professional, responsive, and truly cares about the success of our business. We highly recommend Positivus to any company looking to grow their online presence."',
    //     photo: images.team01,

    // },
];

const Faqs = [
    {
        question: 'Vilken är den senaste versionen av ChatGPT?',
        answer: 'ChatGPT 4-o (omni) heter den senaste, snabbaste versionen av ChatGPT: Den är en så kallad multimodal modell som kan hantera bilder, ljud och text.',
    },
    {
        question: 'ChatGPT på Svenska?',
        answer: 'ChatGPT är faktiskt riktigt bra på svenska. Även fast största delen av träningsdatan är på engelska så är ChatGPT bra på att översätta till svenska.',
    },
    {
        question: 'Är ChatGPT gratis?',
        answer: `Ja, ChatGPT finns i en gratis version. OpenAI erbjuder både en gratisversion och en betald version av ChatGPT. Gratisversionen ger tillgång till basfunktioner, men har vissa begränsningar, som t.ex. tillgång till äldre modeller och lägre prioritet vid hög belastning.

Den betalda versionen, kallad ChatGPT Plus, kostar $20 per månad och ger tillgång till den senaste GPT-4-modellen (4-o) samt snabbare svarstider och högre tillgänglighet även under tider med hög trafik.`,
    },
    {
        question: 'Vad kostar ChatGPT 4?',
        answer: 'PLUS versionen av ChatGPT där ChatGPT 4 ingår kostar 20$/månad eller 25$/månad om du har en enterpriselösning.',
    },
];

export default { Menu, USP, CaseStudies, Team, Testimonials, ServicesData, Faqs, ServicesExtendedData, ProductsData };

