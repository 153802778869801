import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';

const VideoContent = ({ title, ytLink, description, contentLinks }) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    const formattedDescription = description.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
    const screenWidthWithPadding = screenWidth * 0.7;
    return (
        <div className="container">

            <h1>{title}</h1>
            <iframe
                width={screenWidthWithPadding} height={screenWidthWithPadding*0.5625}
                className="rounded-corners"
                src={"https://www.youtube.com/embed/" + ytLink}
                title="YouTube video player"
                frameBorder="0" // Note: React uses camelCase for attribute names
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin" // Note: Corrected spelling
                allowFullScreen // Note: React uses camelCase for attribute names
            ></iframe>
            <div class="description">
                <br/>
                {formattedDescription}
            </div>
            <div>
                <br/>
                {contentLinks && contentLinks.map((link, index) => (
                    <div key={index}>
                        <br />
                        {link.header && <h3>{link.header}</h3>}
                        <a key={index} href={link.link} target="_blank">{link.title}</a>
                        <br />
                    </div>
                ))}
                <br/>
            </div>
            <br/>
        </div>
    );
};

export default VideoContent;
