import React from 'react';
import { Helmet } from 'react-helmet';
import LogEvent from '../../components/Firebase/LogEvent';
import Lottie from 'react-lottie';
import animationData from '../../assets/animations/celebrate.json';

const ThankYou = () => {

  const handleToCourseClick = () => {
    window.location.href = '/kurs';
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  LogEvent("chatgpt_course_purchase")
  return (

    <div style={{ textAlign: "center" }}>
      <Helmet>
        <title> Tack för ditt köp | techlära.se </title>
      </Helmet>
      <br /><br /><br />
      <Lottie options={defaultOptions} height={400} width={400} />
      <br />
      <h1>Tack så mycket för ditt köp! </h1>
      <p>Vi hoppas du får en lärorik kurs.</p>
      <p>Innan du börjar kursen behöver du verifiera din mail.</p>
      <div className="flex items-center justify-between">
        <button className="btn-green-small" onClick={handleToCourseClick}> Vidare till kursen</button>
      </div>
    </div>
  );
};

export default ThankYou;
