import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import TagManager from 'react-gtm-module'

const firebaseConfig = {
  apiKey: "AIzaSyBQoDmb9MSNgff8dzkBkvHAuSHzUp0R6wM",
  authDomain: "techlara-d0988.firebaseapp.com",
  projectId: "techlara-d0988",
  storageBucket: "techlara-d0988.appspot.com",
  messagingSenderId: "708860253567",
  appId: "1:708860253567:web:6f2d10b2659a00cf1e764a",
  measurementId: "G-KJF7MPDMT5"
};

const tagManagerArgs = {
  gtmId: 'GTM-K32642S2'
}
TagManager.initialize(tagManagerArgs)

export const fireBaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(fireBaseApp);
export const auth = getAuth(fireBaseApp);